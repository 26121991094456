import {HTTP} from '../utils/axios'

class BlockModel extends HTTP {
  /*首页渲染*/
  index() {
      return this.request({
          url: 'board/blocks',
          method: "GET"
      })
  }

  /*修改*/
  update(value, id) {
    return this.request({
        url: "board/blocks/" + id,
        method: "PATCH",
        data: value,
    })
  }

  /*置顶*/
  set_top(value) {
    return this.request({
        url: 'board/blocks/set_top',
        method: "PUT",
        data: value
    })
  }

  /*后移*/
  move_next(value) {
    return this.request({
        url: 'board/blocks/move_next',
        method: "PUT",
        data: value
    })
  }
}

export {BlockModel}