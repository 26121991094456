<template>
  <div>
    <a-table
      class="sm-mt-20"
      :columns="columns"
      :dataSource="data"
      :pagination="false"
      :loading="loading"
    >
      <template slot="name" slot-scope="text, record">
        <a
          @click="getDetail(record)"
          :class="`${text == '全部' || record.num == 0 ? 'black' : ''}`"
          >{{ text }}</a
        >
      </template>
      <!-- <template slot="visible" slot-scope="text, record">
            <a-switch :default-checked="text" @change="onChange(record)" />
          </template>
          <span slot="action" slot-scope="text, record">
            <a href="javascript:;" @click="handleById(record)">编辑</a>
            <a-divider type="vertical" />
            <a href="javascript:;" @click="handleSetTop(record)" class="green">置顶</a>
            <a-divider type="vertical" />
            <a href="javascript:;" @click="handleMoveNext(record)" class="green">后移</a>
          </span> -->
    </a-table>
    <a-modal
      title="编辑"
      v-model="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确认"
      cancelText="取消"
    >
      <a-form
        :form="form2"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 16 }"
      >
        <a-form-item label="栏目名称">
          <a-input
            v-decorator="[
              'name',
              { rules: [{ required: true, message: '请输入栏目名称!' }] },
            ]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { BlockModel } from "../../models/block";
import { mapMutations } from "vuex";
const blockModel = new BlockModel();
const columns = [
  {
    title: "栏目名称",
    dataIndex: "name",
    key: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "软件数量",
    dataIndex: "num",
    key: "num",
  },
  // {
  //   title: '是否显示',
  //   dataIndex: 'visible',
  //   key: 'visible',
  //   scopedSlots: { customRender: 'visible' }
  // },
  // {
  //   title: '操作',
  //   dataIndex: '',
  //   key: 'action',
  //   scopedSlots: {
  //     customRender: 'action'
  //   }
  // }
];
export default {
  data() {
    return {
      data: [],
      editId: 0,
      columns,
      visible: false,
      loading: false,
      total: 0,
      form2: this.$form.createForm(this, { name: "coordinated" }),
    };
  },

  created() {
    this.queryBlockList();
  },

  methods: {
    onChange(record) {
      blockModel.update({ visible: !record.visible }, record.id).then((res) => {
        if (res.code == 0) {
          this.visible = false;
          this.$notification.open({
            message: "提示",
            description: res.message,
            duration: 2,
          });
          this.queryBlockList();
        }
      });
    },

    handleById(record) {
      this.visible = true;
      this.editId = record.id;
      console.log(record, this.visible);
      this.$nextTick(() => {
        this.form2.setFieldsValue({
          name: record.name,
        });
      });
    },

    handleSetTop(record) {
      this.$confirm({
        content: "确定要置顶吗？",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          blockModel.set_top({ id: record.id }).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryBlockList();
            }
          });
        },
        onCancel() {},
      });
    },

    handleMoveNext(record) {
      this.$confirm({
        content: "确定要后移吗？",
        okText: "确定",
        cancelText: "取消",
        onOk: () => {
          blockModel.move_next({ id: record.id }).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryBlockList();
            }
          });
        },
        onCancel() {},
      });
    },

    handleOk(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        if (!err) {
          blockModel.update(values, this.editId).then((res) => {
            if (res.code == 0) {
              this.visible = false;
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.queryBlockList();
            }
          });
          this.form2.resetFields();
        }
      });
    },

    handleCancel() {
      this.form2.resetFields();
    },

    getDetail(record) {
      if ([1, 2, 3].includes(record.id) && record.num != 0) {
        this.$router.push({
          path: `/dashboard/${record.id}`,
        });
        this.parentName(record.name);
      }
    },

    queryBlockList() {
      blockModel.index().then((res) => {
        if (res.code == 0) {
          res.result.map((item, index) => {
            item.key = index;
            if (item.name == "推荐") {
              item.num = res.reco_num;
            } else if (item.name == "热门") {
              item.num = res.hot_num;
            } else if (item.name == "最新") {
              item.num = res.latest_num;
            } else {
              item.num = res.all_num;
            }
            return item;
          });
          this.data = res.result;
        }
      });
    },

    ...mapMutations({
      parentName: "PARENT_NAME",
    }),
  },
};
</script>

<style lang="scss" scoped>
.black {
  color: #333;
}
</style>